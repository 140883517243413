import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './@pages/pages.component';
import { LoginComponent } from './login/login.component';
import { ForgottenComponent } from './forgotten-pass/forgotten-pass.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { LoginGuard } from './guards/login.guard';
import { PagesNotFoundComponent } from './pages-not-found/pages-not-found.component';
import { LogoutGuard } from './guards/logout.guard';
import { ActivateUserComponent } from './activate-user/activate-user.component';

const routes: Routes = [
{path:'login', component: LoginComponent,canActivate:[LogoutGuard]},
{path:'changePass', component: ChangePassComponent},
{path:'forgottenPass', component: ForgottenComponent},
{path:'activateUser', component: ActivateUserComponent},
{path:'countries', component: PagesComponent,canActivate: [LoginGuard], loadChildren:()=> import("./@pages/country/country.module").then(m => m.ListModule)},
{path:'provinces',component:PagesComponent,canActivate: [LoginGuard],  loadChildren:()=> import('./@pages/province/province.module').then(m => m.ProvinceModule)},
{path:'towns',component:PagesComponent,canActivate: [LoginGuard],  loadChildren:()=> import('./@pages/town/list-towns.module').then(m => m.ListTownsModule)},
{path:'customers',component:PagesComponent,canActivate: [LoginGuard], loadChildren:()=> import('./@pages/customer/customer.module').then(m => m.CustomerListModule)},
{path:'materials',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/material/material.module').then(m => m.NewMaterialModule)},
{path:'budgets',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/budget/budgets.module').then(m => m.BudgetsModule)},
{path:'delivery',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/delivery/delivery.module').then(m => m.DeliveryListModule)},
{path:'home',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/home/home.module').then(m => m.HomeModule)},
{path:'invoices',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/invoice/invoice.module').then(m => m.InvoiceModule)},
{path:'user',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/user/user.module').then(m => m.UserModule)},
{path:'inventario',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/inventario/inventario.module').then(m => m.InventarioModule)},
{path:'marca',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/inventario/marca/marca.module').then(m => m.MarcaModule)},
{path:'scan',component:PagesComponent, canActivate: [LoginGuard], loadChildren:()=> import('./@pages/scan/scan.module').then(m => m.ScanModule)},
{path:'', pathMatch:'full', redirectTo:"home" },
{path:'**', pathMatch:'full', component: PagesNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
