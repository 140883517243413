
<p-menubar  [model]="items" [autoDisplay]="false" [style]="{'font-size':'0.9rem'}">
  <button
    #btn
    type="button"
    pButton
    icon="pi pi-user"
    class="p-button-rounded button-user"
    (click)="menu.toggle($event)"
  > </button>
  <p-slideMenu
    #menu
    [model]="userMenu"
    [popup]="true"
    [viewportHeight]="250"
  ></p-slideMenu>
</p-menubar>
