import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';

// primeNg
import { MenubarComponent } from '../@share/menubar/menubar.component';
import { MenubarModule } from "primeng/menubar";
import { ButtonModule } from "primeng/button";
import { PanelModule } from "primeng/panel";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { SlideMenuModule } from 'primeng/slidemenu';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from "primeng/dialog";
import { ToggleButtonModule } from "primeng/togglebutton";
import { InputNumberModule } from "primeng/inputnumber";
import { ProgressModule } from '../@share/progress/progress.module';
import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { PickListModule } from 'primeng/picklist';
import { BadgeModule } from 'primeng/badge';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [PagesComponent, MenubarComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    MenubarModule,
    PanelModule,
    BreadcrumbModule,
    SlideMenuModule,
    ToastModule,
    ConfirmDialogModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    CheckboxModule,
    RadioButtonModule,
    CardModule,
    AutoCompleteModule,
    SelectButtonModule,
    DialogModule,
    ToggleButtonModule,
    InputNumberModule,
    ProgressModule,
    CalendarModule,
    PipesModule
   
  ],
  exports: [
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    ButtonModule,
    TableModule,
    DropdownModule,
    PanelModule,
    InputTextModule,
    CheckboxModule,
    RadioButtonModule,
    CardModule,
    AutoCompleteModule,
    SelectButtonModule,
    DialogModule,
    ToggleButtonModule,
    InputNumberModule,
    MenubarModule,
    ProgressModule,
    SidebarModule,
    MultiSelectModule,
    CalendarModule,
    PickListModule,
    BadgeModule,
    PipesModule
  ]
})
export class PagesModule { }
